@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import 'pure-react-carousel/dist/react-carousel.es.css';

  * {
    touch-action: manipulation;
  }

  html {
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
                 Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
                 "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji",
                 "Android Emoji", "EmojiSymbols", "EmojiOne Mozilla",
                 "Twemoji Mozilla", "Segoe UI Symbol", "Segoe UI Symbol";
    font-size: 16px;
    --app-height: 100vh;
    --app-width: 100vw;
    --app-global-scrollbar-width: 0px;
    height: 100%;
  }

  body,
  body #__next {
    min-width: inherit;
    height: 100%;
  }

  body {
    /**
     * Always show the scrollbar. This prevents layout jitter when navigating
     * between pages that do and do not overflow.
     */
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: var(--app-global-scrollbar-width);
  }

  video {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  input {
    font-family: Montserrat;
    font-weight: 500;
  }

  @screen mobile {
    html {
      min-width: 320px;
    }
  }

  @screen desktop {
    html {
      min-width: 1200px;
      font-size: 12px;
    }
  }

  @screen desktop-lg {
    html {
      min-width: 1700px;
      font-size: 16px;
    }
  }
}
